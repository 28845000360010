import { executeOnSingleOrMultiple, isArray, isInArray, } from "tsparticles-engine";
import { Absorber } from "./Options/Classes/Absorber";
import { Absorbers } from "./Absorbers";
class AbsorbersPlugin {
    constructor() {
        this.id = "absorbers";
    }
    getPlugin(container) {
        return new Absorbers(container);
    }
    loadOptions(options, source) {
        if (!this.needsPlugin(options) && !this.needsPlugin(source)) {
            return;
        }
        if (source?.absorbers) {
            options.absorbers = executeOnSingleOrMultiple(source.absorbers, (absorber) => {
                const tmp = new Absorber();
                tmp.load(absorber);
                return tmp;
            });
        }
        options.interactivity.modes.absorbers = executeOnSingleOrMultiple(source?.interactivity?.modes?.absorbers, (absorber) => {
            const tmp = new Absorber();
            tmp.load(absorber);
            return tmp;
        });
    }
    needsPlugin(options) {
        if (!options) {
            return false;
        }
        const absorbers = options.absorbers;
        if (isArray(absorbers)) {
            return !!absorbers.length;
        }
        else if (absorbers) {
            return true;
        }
        else if (options.interactivity?.events?.onClick?.mode &&
            isInArray("absorber", options.interactivity.events.onClick.mode)) {
            return true;
        }
        return false;
    }
}
export async function loadAbsorbersPlugin(engine, refresh = true) {
    await engine.addPlugin(new AbsorbersPlugin(), refresh);
}
export * from "./AbsorberContainer";
export * from "./Enums/AbsorberClickMode";
